import { UseMutationResult, useMutation } from '@tanstack/react-query';
import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';
import { useAuth } from '../auth/auth.hooks';

interface DocRequestDataForEmail {
  title: string;
  comment: string;
  filters: string;
}

interface DocRequestData {
  title: string;
  comment: string;
  productReferenceType: string;
  productNumber: string;
  mainType: string;
  subType: string;
  tkbFilters: string;
}

export const useMissingDocRequest = (): UseMutationResult<
  DocRequestData,
  unknown,
  DocRequestData,
  unknown
> => {
  const { api } = useAuth();
  return useMutation(
    (docRequest: DocRequestData) =>
      api
        .post('/missing-doc', docRequest)
        .then((response: { status: number; data: any }) => {
          if (response.status !== 200) {
            throw new Error();
          }
          return response.data;
        }),
    {
      onSuccess: () => {
        UIKit.showToast('Missing document request registered successfully.', {
          timeout: 5000,
          title: 'Request sent successfully',
          variant: ToastVariant.Success,
          position: ToastPosition.BottomRight,
        });
      },
      onError: () => {
        UIKit.showToast(
          'Failed to send missing document request due to technical issues. Please try again later.',
          {
            title: 'Error!',
            variant: ToastVariant.Warning,
            position: ToastPosition.BottomRight,
          }
        );
      },
    }
  );
};

export const useMissingDocRequestViaEmail = (): UseMutationResult<
  DocRequestDataForEmail,
  unknown,
  DocRequestDataForEmail,
  unknown
> => {
  const { api } = useAuth();
  return useMutation(
    (docRequest: DocRequestDataForEmail) =>
      api
        .post('/email/missing-doc', docRequest)
        .then((response: { status: number; data: any }) => {
          if (response.status !== 200) {
            throw new Error();
          }
          return response.data;
        }),
    {
      onSuccess: () => {
        UIKit.showToast('Missing document request sent successfully.', {
          timeout: 5000,
          title: 'Request sent successfully',
          variant: ToastVariant.Success,
          position: ToastPosition.BottomRight,
        });
      },
      onError: () => {
        UIKit.showToast('Failed to send missing document request.', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        });
      },
    }
  );
};
