/* eslint-disable @typescript-eslint/no-non-null-assertion */
import './documents.styles.scss';

import * as React from 'react';

import UIKit, * as WUI from '@wartsila/ui-kit';
import { findKey, forEach, isEqual, values } from 'lodash';

import DataTable from 'react-data-table-component';
import { ReactComponent as DocWarning } from '@wartsila/ui-kit/icons/doc_warning.svg';
import { ReactComponent as EmptyStarSVG } from '@wartsila/ui-kit/icons/empty_star.svg';
import { ReactComponent as HalfStarSVG } from '@wartsila/ui-kit/icons/half_star.svg';
import { ReactComponent as InfoSVG } from '@wartsila/ui-kit/icons/info.svg';
import { ReactComponent as MinusIconSVG } from '@wartsila/ui-kit/icons/box_min.svg';
import { ReactComponent as PlusIconSVG } from '@wartsila/ui-kit/icons/plus2.svg';
import { ReactComponent as StarSVG } from '@wartsila/ui-kit/icons/star.svg';
import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import { documentDictionary } from './documents.dictionary';
import {
  filterDictionary,
  subTypeDictionary,
} from '../filters/filters.dictionary';
import messagesJson from './documents.messages.json';
import { useBookmark, useDocument } from './documents.hooks';
import { Document, DocumentsResponse } from './documents.types';
import { queryClient } from '../..';
import { useFilterTypes } from '../filters/filters.hooks';
import { useMergeAndDownload } from '../file/file.hooks';
import { MissingDocRequest } from '../docrequest/docrequest.component';
import { HTMLContent } from '../documentContent/documentContent.components';
import { useAuth } from '../auth/auth.hooks';
import { AssignmentsFilter } from '../filters/filters.types';

const FOR_IMMEDIATE_ATTENTION = 'For immediate attention';
const { warningIconMessage } = messagesJson;

const iconStyles = {
  width: 28,
  height: 28,
  fill: '#086795',
  cursor: 'pointer',
};

const infoIconStyles = {
  fill: iconStyles.fill,
};

const warningIconStyles = {
  fill: 'red',
};

const expandableIcon = {
  expanded: <MinusIconSVG style={iconStyles} />,
  collapsed: <PlusIconSVG style={iconStyles} />,
};

const customStyles = {
  table: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  rows: {
    style: {
      alignItems: 'start',

      borderRadius: 2,
      padding: '0.5rem 0',
      marginBottom: '0.5rem',

      '&:last-of-type': {
        marginBottom: '0',
      },

      '&:not(:last-of-type)': {
        borderBottomWidth: '0',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
      },
    },
  },
  expanderRow: {
    style: {
      backgroundColor: 'transparent',
      transform: 'translateY(-0.55rem)',

      '&:last-of-type': {
        marginBottom: '-0.55rem',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      ':nth-of-type(-n+2)': {
        alignSelf: 'center',
      },

      ':last-of-type': {
        alignSelf: 'center',
        minWidth: '50px',
      },

      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

const conditionalRowStyles = [
  {
    when: (row: Document) => row.actionCode === FOR_IMMEDIATE_ATTENTION,
    style: { boxShadow: 'inset 3px 0 0 #ff7321;' },
  },
];

const Cell = ({
  title,
  style,
  content,
  internalComment,
  linkedBulletins,
}: {
  title?: string;
  content?: string;
  internalComment?: string;
  linkedBulletins?: boolean;
  style?: React.CSSProperties;
}): JSX.Element | null => (
  <div className="document__cell" style={style}>
    <div className="document__cell-title">{title}</div>
    {internalComment && (
      <div title={internalComment} className="document__cell-icon">
        <InfoSVG style={infoIconStyles} />
      </div>
    )}
    {linkedBulletins && (
      <div
        title={warningIconMessage}
        className="document__cell-icon-linked-bulletins">
        <DocWarning style={warningIconStyles} />
      </div>
    )}
    <div
      className="document__cell-content"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content || '-' }}
    />
  </div>
);

const columnKeys = [
  { key: 'documentNumber', grow: 2 },
  { key: 'title', grow: 4 },
  { key: 'mainType', grow: 2 },
  { key: 'subType', grow: 2 },
  { key: 'visibility', grow: 1 },
  { key: 'productReferenceTypes', grow: 2 },
  { key: 'documentDate', grow: 1 },
  { key: 'isBookmarked', grow: 0 },
];

const columns = columnKeys.map((column) => ({
  grow: column.grow,
  selector: column.key,
  name: get(documentDictionary, column.key),
  cell: (data: Document) => {
    const filters = useFilterTypes();
    const { create, remove } = useBookmark();

    const isGroupedDocument = data.numberOfChildren > 0;

    let content = get(data, column.key);
    const title = get(documentDictionary, column.key);

    if (column.key === 'documentDate') {
      content = moment(content).format('DD.MM.YYYY');
    }

    if (column.key === 'mainType') {
      content = get(filterDictionary, content, content);
    }

    if (column.key === 'subType') {
      const type = filters.data?.documentTypes.find(
        (documentType) => documentType.value === data.mainType
      );
      const subTypeLabel = type?.children?.find(
        (subType) => subType.value === data.subType
      )?.value;

      content = subTypeLabel ? get(subTypeDictionary, subTypeLabel) : content;
    }

    if (column.key === 'productReferenceTypes') {
      if (Array.isArray(content)) {
        content =
          content.length > 1
            ? `${content.join(', ').slice(0, 12)}...`
            : content.toString();
      }
    }

    if (isGroupedDocument) {
      // We have to manually re-write some of the cells content and their style
      const enabledFields = ['documentDate', 'documentNumber', 'subType'];

      if (enabledFields.some((field) => field === column.key)) {
        if (column.key === 'subType') {
          return (
            <Cell
              style={{ paddingLeft: '0.5rem' }}
              content={data.numberOfChildren.toString()}
              title={get(documentDictionary, 'numberOfChildren')}
            />
          );
        }

        if (column.key === 'documentDate' && content === 'Invalid date') {
          return null;
        }

        if (column.key === 'documentNumber') {
          return (
            <Cell
              content={data.title}
              style={{
                lineHeight: 1.85,
                maxWidth: '375%',
                flexShrink: 0,
              }}
            />
          );
        }

        return (
          <Cell
            style={{
              paddingLeft: column.key === 'documentDate' ? '0' : '0.75rem',
            }}
            title={title}
            content={Array.isArray(content) ? content.toString() : content}
          />
        );
      }

      return null;
    }

    if (column.key === 'title') {
      return (
        <Cell
          title={title}
          content={content}
          style={{ paddingLeft: '1rem', marginLeft: '1rem' }}
          internalComment={data.internalComment}
          linkedBulletins={
            data.linkedBulletinIds !== null &&
            data.linkedBulletinIds.length !== 0
          }
        />
      );
    }

    if (column.key === 'isBookmarked') {
      if (remove.isLoading || create.isLoading) {
        return <HalfStarSVG style={iconStyles} />;
      }

      return data.isBookmarked ? (
        <StarSVG onClick={() => remove.mutate(data.id)} style={iconStyles} />
      ) : (
        <EmptyStarSVG
          onClick={() => create.mutate(data.id)}
          style={iconStyles}
        />
      );
    }

    return (
      <Cell
        title={title}
        content={Array.isArray(content) ? content.toString() : content}
      />
    );
  },
}));

const DocumentDetails = ({
  data,
  tabs,
  filters,
  selectRows,
  hasPreview,
  selectedDocuments,
}: {
  data?: Document;
  hasPreview?: boolean;
  selectedDocuments: Document[];
  filters?: { [key: string]: unknown };
  selectRows: (documents: Document[]) => void;
  tabs: (document: Document, hasPreview: boolean) => React.ReactNode;
}): JSX.Element => {
  const subDocuments = useDocument(
    data!.id,
    Boolean(data!.numberOfChildren),
    filters
  );

  const [showRelatedListOfDocuments, setShowRelatedListOfDocuments] =
    React.useState<boolean>(false);

  const hideDownloadCompleteDocumentButton =
    data?.subType === 'Installation Manual' ||
    data?.subType === 'Performance Manual' ||
    data?.isStationManual ||
    subDocuments.data?.payload.results.some(
      (sub) =>
        sub.isStationManual ||
        sub.subType === 'Installation Manual' ||
        sub.subType === 'Performance Manual' ||
        sub.relatedGroupedFiles?.length !== 0
    );

  const { download, downloading } = useMergeAndDownload(
    data!.id,
    subDocuments.data?.payload.results
  );

  const relatedListOfDocumentFile =
    subDocuments.data?.payload.results[0].relatedGroupedFiles?.find(
      (file) => file.type === 'text/html'
    );

  const relatedPDFPrintFile =
    subDocuments.data?.payload.results[0].relatedGroupedFiles?.find(
      (file) => file.type === 'application/pdf'
    );

  const { api } = useAuth();

  return (
    <div>
      {data?.numberOfChildren === null && (
        <div
          className={classNames(
            'document',
            data?.actionCode === FOR_IMMEDIATE_ATTENTION
              ? 'document_warning'
              : null
          )}>
          {data && tabs(data, Boolean(hasPreview))}
        </div>
      )}

      {subDocuments.isFetching ? (
        <div className="documents__children">
          <WUI.Loader className="documents__loader" />
        </div>
      ) : null}

      {subDocuments.isSuccess ? (
        <div className="documents__children">
          {hideDownloadCompleteDocumentButton ? null : (
            <WUI.Button
              variant={WUI.ButtonVariant.Accent}
              loading={downloading}
              onClick={() => {
                download();
                UIKit.showToast(
                  'Merging documents files. This might take several minutes',
                  {
                    timeout: 5000,
                    variant: WUI.ToastVariant.Info,
                    position: WUI.ToastPosition.BottomRight,
                  }
                );
              }}>
              Download complete document
            </WUI.Button>
          )}

          {relatedPDFPrintFile ? (
            <WUI.Button
              variant={WUI.ButtonVariant.Accent}
              onClick={() =>
                api
                  .get<{ data: string }, { data: string }>(
                    `/file/${relatedPDFPrintFile.id}`,
                    {
                      params: {
                        contentType: relatedPDFPrintFile.type,
                      },
                    }
                  )
                  .then(({ data: url }) => {
                    window.open(url, '_blank');
                  })
              }>
              Download print file
            </WUI.Button>
          ) : null}

          {relatedListOfDocumentFile ? (
            <WUI.Button
              variant={WUI.ButtonVariant.Accent}
              loading={downloading}
              onClick={() =>
                setShowRelatedListOfDocuments(!showRelatedListOfDocuments)
              }>
              View list of documents
            </WUI.Button>
          ) : null}

          {showRelatedListOfDocuments && relatedListOfDocumentFile ? (
            <div className="documents__frame">
              <HTMLContent
                file={relatedListOfDocumentFile}
                onLoad={() => ({})}
                search=""
                documentId={subDocuments.data?.payload.results[0].id}
                installationIds=""
                equipmentIds=""
              />
            </div>
          ) : null}
          <DataTable
            noHeader
            noTableHead
            selectableRows
            expandableRows
            columns={columns}
            customStyles={customStyles}
            expandableIcon={expandableIcon}
            conditionalRowStyles={conditionalRowStyles}
            data={subDocuments.data.payload.results.concat()}
            selectableRowDisabled={(row) =>
              Boolean(row.numberOfChildren) ||
              row.subType === 'Technical FAQ' ||
              row.subType === 'Closed TechRequest' ||
              row.subType === 'Performance Manual' ||
              row.subType === 'Installation Manual' ||
              (Boolean(row.files.length) &&
                Object.values(row.files).every(
                  (file) => file.type === 'text/html'
                ))
            }
            selectableRowSelected={(row) =>
              Boolean(selectedDocuments.find((doc) => doc.id === row.id))
            }
            onSelectedRowsChange={(state) =>
              selectRows(
                selectedDocuments
                  .filter(
                    (doc) =>
                      !subDocuments.data.payload.results.some(
                        (sd) => sd.id === doc.id
                      )
                  )
                  .concat(state.selectedRows)
              )
            }
            expandableInheritConditionalStyles
            expandableRowsComponent={
              <DocumentDetails
                filters={filters}
                selectRows={selectRows}
                selectedDocuments={selectedDocuments}
                tabs={(document) => tabs(document, Boolean(hasPreview))}
              />
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export const DocumentsTable = ({
  tabs,
  filters,
  sorting,
  children,
  exporting,
  selectRows,
  downloading,
  exportDocuments,
  downloadDocuments,
  exportingDisabled,
  selectedDocuments,
  dowloadingDisabled,
  totalDocuments = 0,
  selectedAssignmentFilters,
  selectedDocumentTypes,
}: {
  exporting: boolean;
  children: Document[];
  downloading: boolean;
  totalDocuments: number;
  sorting: React.ReactNode;
  exportingDisabled: boolean;
  dowloadingDisabled: boolean;
  selectedDocuments: Document[];
  exportDocuments: () => unknown;
  downloadDocuments: () => unknown;
  filters?: { [key: string]: unknown };
  selectRows: (documents: Document[]) => unknown;
  tabs: (document: Document) => React.ReactNode;
  selectedAssignmentFilters?: Partial<AssignmentsFilter>;
  selectedDocumentTypes?: string[];
}): JSX.Element => {
  const formatFilters = (): string => {
    const formatedFilters: string[] = [];

    forEach(filters, (filter, filterName) => {
      if (!filter) return;

      if (typeof filter === 'string') {
        formatedFilters.push(
          `${filter} (${get(filterDictionary, filterName, filterName)})`
        );
        return;
      }

      if (typeof filter === 'boolean') {
        formatedFilters.push(get(filterDictionary, filterName, filterName));
        return;
      }

      if (typeof filter === 'object' || filter !== null) {
        // Exception for Spare Parts Catalogue - this is a maintype without subtypes and should be displayed differently
        const exceptionMainTypeSPC = 'SPC';
        if (Object.keys(filter).includes(exceptionMainTypeSPC)) {
          formatedFilters.push(
            `${get(filterDictionary, exceptionMainTypeSPC)}`
          );
        }
        forEach(values(filter), (subTypes: string[] | never) => {
          if (subTypes?.length) {
            const formattedSubTypes: string[] = [];
            forEach(
              subTypes,
              (subType) => subType && formattedSubTypes.push(subType)
            );

            const usedFilter =
              findKey(filter, (value) => isEqual(value, subTypes)) || '';

            forEach(formattedSubTypes, (subType) =>
              formatedFilters.push(
                `${subType} (${get(filterDictionary, usedFilter, usedFilter)})`
              )
            );
          }
        });
      }
    });
    return formatedFilters.join(', ');
  };

  return (
    <>
      <div className="documents">
        <div className="documents__header-container">
          <div className="documents__header">
            <div className="documents__title">Documents ({totalDocuments})</div>
            <div className="documents__title-buttons">
              <WUI.Button
                loading={exporting}
                disabled={exportingDisabled || exporting}
                onClick={exportDocuments}>
                Export to Excel
              </WUI.Button>
              <WUI.Button
                onClick={() => {
                  if (selectedDocuments.length) {
                    selectRows([]);
                    return;
                  }

                  const documentsWithoutGroups = children.filter(
                    (document) => !document.id.includes('grouped_')
                  );
                  const queries = queryClient.getQueriesData<DocumentsResponse>(
                    ['document', 'grouped', filters]
                  );
                  const nestedDocuments = queries
                    .filter((query) => Boolean(query[1]))
                    .flatMap((query) => query[1].payload.results)
                    .filter((document) => !document.id.includes('grouped_'));
                  selectRows(
                    documentsWithoutGroups
                      .concat(nestedDocuments)
                      .filter(
                        (document) =>
                          document.subType !== 'Technical FAQ' &&
                          document.subType !== 'Closed TechRequest'
                      )
                  );
                }}>
                {selectedDocuments.length ? 'Deselect all' : 'Select all'}
              </WUI.Button>
              <WUI.Button
                loading={downloading}
                disabled={dowloadingDisabled || downloading}
                onClick={downloadDocuments}>
                Download selected{' '}
                {selectedDocuments.length
                  ? `(${selectedDocuments.length})`
                  : ''}
              </WUI.Button>
            </div>
          </div>
          <div className="documents__header">{sorting}</div>
        </div>
        <DataTable
          noHeader
          noTableHead
          selectableRows
          expandableRows
          data={children}
          columns={columns}
          customStyles={customStyles}
          expandableIcon={expandableIcon}
          selectableRowDisabled={(row) =>
            Boolean(row.numberOfChildren) ||
            [
              'Technical FAQ',
              'Closed TechRequest',
              'Installation Manual',
              'Performance Manual',
            ].includes(row.subType)
          }
          onSelectedRowsChange={(state) =>
            selectRows(
              selectedDocuments
                .filter((doc) => !children.some((sd) => sd.id === doc.id))
                .concat(state.selectedRows)
            )
          }
          selectableRowSelected={(row) =>
            Boolean(selectedDocuments.find((doc) => doc.id === row.id))
          }
          conditionalRowStyles={conditionalRowStyles}
          expandableInheritConditionalStyles
          noDataComponent={
            <div className="documents__empty">
              <b>No documents found for:</b> &quot;{formatFilters()}&quot;
              <div className="documents__empty__button">
                <MissingDocRequest
                  filters={filters}
                  selectedAssignmentFilters={selectedAssignmentFilters}
                  selectedDocumentTypes={selectedDocumentTypes}
                />
              </div>
            </div>
          }
          expandableRowExpanded={() => totalDocuments === 1}
          expandableRowsComponent={
            <DocumentDetails
              filters={filters}
              hasPreview={false}
              selectRows={selectRows}
              tabs={(document) => tabs(document)}
              selectedDocuments={selectedDocuments}
            />
          }
        />
      </div>
    </>
  );
};
